import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import SelectActiveCampaignScreen from "./SelectActiveCampaign.component";

class LoginScreen extends Component {
	constructor(props) {
		super(props);
		const userId = new URLSearchParams(this.props.location.search).get("userId");
		this.state = {
			userId: userId,
		};
	}

	attemptLogin = (e) => {
		e.preventDefault();
		const userid = this.userid.value.trim();
		const password = this.claimedpassword.value.trim();
		this.props.login(userid, password);
	};

	handleUserIdChange = (e) => {
		this.setState({ userId: e.target.value });
	};

	render() {
		const { loadingLogin: loading, errorLogin, errorMessage, userid, jwt, campaignid } = this.props;

		if (userid && jwt && campaignid && !errorLogin && !loading) {
			return <Redirect to="/" />;
		}

		return (
			<div className="dcm-sender-promptserver-modal">
				{!userid && (
					<form onSubmit={this.attemptLogin}>
						<br />
						Please Log In
						<br />
						<input
							type="text"
							name="username"
							placeholder="username"
							autoFocus={true}
							value={this.state.userId}
							onChange={this.handleUserIdChange}
							ref={(userid) => {
								this.userid = userid;
							}}
						/>
						<br />
						<input
							type="password"
							autoComplete="on"
							name="claimedpassword"
							placeholder="password"
							ref={(claimedpassword) => {
								this.claimedpassword = claimedpassword;
							}}
						/>
						<br />
						<div className="div-28">
							<button className="button-one12" type="submit">
								Log In
							</button>
						</div>
						{errorMessage && <p style={{ color: "red", fontSize: "10px" }}>{errorMessage}</p>}
						{this.props.logoutMessage && (
							<p style={{ color: "red", fontSize: "10px" }}>
								You have been logged out. {this.props.logoutMessage}
							</p>
						)}
						{this.state.previousResult}
					</form>
				)}
				{userid && !campaignid && <SelectActiveCampaignScreen {...this.props} />}
			</div>
		);
	}
}

export default LoginScreen;
