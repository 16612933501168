import React from "react";
import RecurringCallbackManager from "../../common/RecurringCallbackManager";
import { logAgentMinute } from "../../redux/agent/thunks";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";

const AgentTimeLogManager = (props) => (
	<RecurringCallbackManager
		intervalTime={1}
		intervalName="agentMinuteLog"
		task={props.logAgentMinute}
		executeTaskAtStart={true}
	/>
);

const AgentLogContainer = reduxConnectedComponent(AgentTimeLogManager, undefined, { logAgentMinute });
export default AgentLogContainer;
