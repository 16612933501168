import React, { useState } from "react";
import ConversationComponent from "./Conversation.component";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";

import {
	updateConversationSender,
	endAndRemoveConversation,
	updateConversationAccepted,
} from "../../redux/recipients/thunks";

import {
	removeConversation,
	updateConversationNextScript,
	updateSelectedRecipient,
} from "../../redux/recipients/actions";

import { togglePromptModal } from "../../redux/selectedRecipient/actions";
import { FeedEventType } from "../../redux/feed/constants";
import { addFeedEvent, getFeedEvent } from "../../redux/feed/actions";
import { TERMINATING_QUESTION_TYPES_STATUS_MAP } from "../../util/constants";

export function ConversationContainer({
	numbers,
	selectedPhone,
	script,
	prompts,
	initialConditionals,
	addTexterAppEvent,
	defaultFirstQuestionId,
	campaignId,
	...props
}) {
	const [previousSelectedPhone, setPreviousSelectedPhone] = useState(selectedPhone);
	const [disableAutoselect, setDisableAutoselect] = useState(false);
	const [selectedAnswerIdx, setSelectedAnswerIdx] = useState(-1);

	if (previousSelectedPhone !== selectedPhone) {
		setPreviousSelectedPhone(selectedPhone);
		setSelectedAnswerIdx(-1); // New convo reset answers.
		setDisableAutoselect(false);
	}

	// for now this will do
	if (!selectedPhone || selectedPhone === "") {
		return <ConversationComponent disabled={true} />;
	}

	const selectedData = numbers[selectedPhone];

	// console.log("Container props", props);
	// Handle nothing selected case

	// NOTE Things should either be name or previousName:
	// currentscriptid OR previousCurrentscriptid
	// phone OR previousPhone
	//
	// For clarity

	// NOTE This if for selecting the answer via the radio boxes
	function selectAnswer(idx) {
		const intValue = Number(idx);
		if (isNaN(intValue)) return;
		setSelectedAnswerIdx(intValue);
	}

	function resetSelectedAnswer(disableAutoselect = false) {
		console.log("Resetting selected answer");
		setSelectedAnswerIdx(-1);
		setDisableAutoselect(disableAutoselect);
	}

	function removeConversationAndNotify(phone, method) {
		if (method === TERMINATING_QUESTION_TYPES_STATUS_MAP.terminating.method) {
			props.addFeedEvent(getFeedEvent(FeedEventType.TERMINATED, phone));
		}
		removeConversation(phone);
	}

	return (
		<ConversationComponent
			// Header data
			headerProps={{
				phone: selectedPhone,
				status: selectedData.status,
				conversation: selectedData.conversation,
			}}
			// camapign data
			script={script}
			prompts={prompts}
			initialConditionals={initialConditionals}
			defaultFirstQuestionId={defaultFirstQuestionId}
			// Selected recipient
			phoneChanged={selectedPhone !== previousSelectedPhone}
			selectedPhone={selectedPhone}
			selectedAnswerIdx={selectedAnswerIdx}
			conversation={selectedData.conversation}
			misc={selectedData.misc}
			currentscriptid={selectedData.currentscriptid}
			justsentscript={selectedData.justsentscript}
			reattempt={selectedData.reattempt}
			disableAutoselect={disableAutoselect}
			// callbacks
			removeConversationAndNotify={removeConversationAndNotify}
			selectAnswer={selectAnswer}
			addTexterAppEvent={addTexterAppEvent}
			resetSelectedAnswer={resetSelectedAnswer}
			{...props}
			// Media container
			campaignId={campaignId}
			jwt={props.jwt}
		/>
	);
}

export default reduxConnectedComponent(
	ConversationContainer,
	(state) => ({
		numbers: state.recipients.numbers,
		defaultFirstQuestionId: state.campaign.defaultfirstquestionid,
	}),
	{
		// Message sending
		updateConversationAccepted,
		updateConversationSender,

		addFeedEvent,
		removeConversation,
		updateConversationNextScript,
		updateSelectedRecipient,
		endAndRemoveConversation,
		togglePromptModal,
	},
);
