import React, { Component } from "react";
import { SERVER_URLROOT } from "../util/constants";
import { requestErrorHandler } from "../util/helper";
import { ajax } from "jquery";

class MediaContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: false,
			errorMsg: "",
			mediaList: null,
		};
	}

	componentDidMount = () => {
		const { jwt, campaignid, disabled } = this.props;
		if (disabled) return this.setData({});

		s160Request(
			"/displayMediaFilesForCampaign",
			{ campaignid },
			"POST",
			() => this.setLoading(true),
			(data) => this.setData(data),
			(jqXHR, textStatus, errorThrown) => {
				this.setError(true, textStatus);
				requestErrorHandler(jqXHR, textStatus, errorThrown);
			},
			jwt,
		);
	};

	setLoading = (loading) => this.setState({ loading });

	setError = (error, msg) => this.setState(error ? { error, errorMsg: msg, loading: false } : { error });

	setData = (data) =>
		this.setState(
			{ mediaList: data, loading: false, error: false, errorMsg: "" },
			// , () => console.log(this.state)
		);

	dataArrayToObject = (data) => {
		return data.reduce((tot, current) => ({ ...tot, [current.mediaId]: current }), {});
	};

	render = () => {
		const { loading, error, errorMsg, mediaList } = this.state;

		if (loading) {
			return <div>Loading...</div>;
		}

		if (error) {
			return <div>{errorMsg}</div>;
		}

		return React.cloneElement(this.props.children, { mediaList });
	};
}

export default MediaContainer;

function s160Request(
	url,
	params,
	method = "POST",
	preRequestCallback = () => {},
	successCallback = () => {},
	failCallback = () => {},
	jwt,
) {
	preRequestCallback();
	const call_getagentstatistics = ajax({
		url: `${SERVER_URLROOT}/${url}`,
		type: method,
		dataType: "json",
		headers: {
			Authorization: jwt,
		},
		data: JSON.stringify(params),
		contentType: "application/json",
		crossDomain: true,
	});
	call_getagentstatistics.done(successCallback);
	call_getagentstatistics.fail(failCallback);
}
