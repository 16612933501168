import Cookies from "js-cookie";
import { fetchRequest } from "../../middleware/fetchMiddleware";
import * as actionTypes from "./types";
import { removeCookieList } from "../../util/helper";
import { push } from "connected-react-router";
import { clearData } from "../common/actions";

/**
 * Log the user in.
 * @param {string} userid
 * @param {string} claimedpassword
 * @returns The request Promise for the login
 */
export const login = (userid, claimedpassword) => (dispatch) => {
	// First clear any data just in case it's around
	dispatch(clearData());
	return dispatch(
		fetchRequest("LOGIN", "POST", "/authenticateagent", {
			userid,
			claimedpassword,
		}),
	).then((data) => {
		// TODO: This doesn't catch errors
		if (!data) {
			return;
		}
		Cookies.set("userid", userid);
		Cookies.set("jwt", data.data);
		Cookies.set("campaignid", data.campaignid);
		Cookies.set("permission", data.permissionlevel);
		dispatch(setUserid(userid));
	});
};

/**
 * Get all launched campaigns that this user has access to.
 * @returns The request Promise for getting the launched campaigns
 */
export const getLaunchedCampaigns = () => (dispatch, getState) => {
	return dispatch(
		fetchRequest("GET_LAUNCHED_CAMPAIGNS", "POST", "/retrieveAllNAIDLaunched", {
			userid: getState().auth.userid,
		}),
	);
};

export const setUserid = (userid) => (dispatch) => dispatch({ type: actionTypes.SET_USERID, userid });

export const setCampaignid = (campaignid) => (dispatch) => dispatch({ type: actionTypes.SET_CAMPAIGNID, campaignid });

/**
 * Log current agent out and release the recipient list
 * @returns The request Promise for logout
 */
export const logout = () => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest("LOGOUT", "POST", "/releaseListAndLogout", {
			userid: state.auth.userid,
			campaignid: state.auth.campaignid,
			broadcast: false,
		}),
	).then(() => {
		dispatch(clearData());
		removeCookieList(["jwt", "userid", "permission"]);
		// dispatch(push("/login"));
	});
};

/**
 * This allows us to set auth data without the endpoint
 * @param {*} data
 * @returns
 */
export function setAuthData(jwt, userid, campaignid, permissionlevel) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_AUTH_DATA,
			jwt,
			userid,
			campaignid,
			permissionlevel,
		});
	};
}

/**
 * Update the JWT token in the store
 * This updates the JWT access token after every API response (b/c it may have been refreshed)
 */
export function updateJWTToken(newAccessToken) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.UPDATE_JWT_TOKEN,
			newAccessToken: newAccessToken,
		});
	};
}

export function authHasError(hasError, message) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.AUTH_HAS_ERROR,
			hasError: hasError,
			errorMessage: message,
		});
	};
}

export function redirectToLogin() {
	return (dispatch) => {
		dispatch(push("/login"));
		dispatch({ type: actionTypes.LOGOUT_POST_SUCCESS });
	};
}
