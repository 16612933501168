import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCircleExclamation, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";

function NotificationsNav({ items = [] }) {
	return (
		<div className="navbar-item is-expanded px-0">
			<div className="notifications container is-flex">
				{items.map((item, index) => (
					<span key={index} className="notif icon" data-badge={item.count} title={item.message}>
						{item.status == "pending" ? (
							<FontAwesomeIcon icon={faSpinner} className="spinning" />
						) : item.status == "error" ? (
							<FontAwesomeIcon icon={faCircleExclamation} className="has-text-danger" />
						) : item.status == "success" ? (
							<FontAwesomeIcon icon={faCheckCircle} className="has-text-success" />
						) : (
							"N"
						)}
					</span>
				))}
			</div>
		</div>
	);
}

export default reduxConnectedComponent(NotificationsNav, (state) => ({ items: state.notifications.queue }));
