/**
  Events are anything that happens in the app that we want to log:
  - errors
  - script errors
  - msg status events: loading, success, error
  - any request errors
  - etc
*/

// TODO: To fade out the events when they are in a final state, we can have a single interval run every second:
// TODO: the interval will:
//        - Filter out the events that are ongoing ( eg loading ). Leave these alone.
//        - If there are events that have (age > cutoff AND age > cutoff + animationTime ) Move them to cold list.
//        - Set the events that are visible but have date > cutoff ( age > 4 seconds) This will trigger their animation fading out
// This should take care of the visuals, and leave a full record in a list somewhere that the agent can click on.

import { v4 as uuidv4 } from "uuid";
import { ADD_TEXTER_APP_EVENT, SET_TEXTER_APP_EVENT_VISIBILITY } from "./types";

export const EVENT_TYPES = Object.freeze({
	ERROR: "error",
	WARNING: "warning",
	INFO: "info",
	SUCCESS: "success",
	DEBUG: "debug",
});

/**
 * Create a event Action
 * @param {symbol} eventType The type / level of the event
 * @param {string} msg The msg to display to the user
 * @param {string} sourceLocation A str description of the source of the error
 * @param {Object} moreInfo Any additional info to store with the event
 * @param {string} id A uuid string. (autogenerated if not passed). This will allow us to update events, after the fact. Example: loading --> success.
 * @returns
 */
export function addTexterAppEvent(eventType, msg, sourceLocation, moreInfo = {}, id = uuidv4()) {
	// console.log("type", eventType)
	return (dispatch) =>
		dispatch({
			type: ADD_TEXTER_APP_EVENT,
			event: { eventType, msg, sourceLocation, id, ...moreInfo },
		});
}

export function setEventVisibility(id, visible) {
	return (dispatch) =>
		dispatch({
			type: SET_TEXTER_APP_EVENT_VISIBILITY,
			id,
			visible,
		});
}
