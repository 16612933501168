import { push } from "connected-react-router";

import { authHasError } from "../auth/actions";
import { removeCookieList } from "../../util/helper";
import { clearData } from "../common/actions";

export function globalHTTPResponseHandler(response, name) {
	return (dispatch) => {
		// Response is positive so do nothing and just return
		if (response.ok) {
			return;
		}

		// Regardless of the status, we want to broadcast the
		// URL and the error message
		response.json().then((data) => {
			let msg = response.message;
			if (data.message) {
				msg = data.message;
			} else if (data.error) {
				msg = data.error;
			}

			dispatch(fetchErrorMessage(name, msg));
			console.log("Server error", response.status, msg);
		});

		const cookieList = ["jwt", "userid", "permission", "campaignid"];

		switch (response.status) {
			case 401:
				// unauthorized
				dispatch(clearData());
				removeCookieList(cookieList);
				dispatch(push("/login"));
				dispatch(authHasError(true, "Unauthorized."));
				break;
			case 403: // not allowed
				removeCookieList(cookieList);
				dispatch(clearData());
				dispatch(push("/login"));
				break;
			case 404: // Not found
				break;
			case 400:
			case 500: // internal server error
				const urlObj = new URL(response.url);
				switch (urlObj.pathname) {
					// Pass along the specific errors we know are being handled
					case "/appendToConversation":
						// do nothing
						console.groupCollapsed("appendToConversation error");
						console.error(response);
						console.groupEnd();
						break;

					default: // TODO: Send to global error display, so we can actually track
						removeCookieList(cookieList);
						dispatch(clearData());
						dispatch(push("/login"));
						break;
				}
				break;
			default:
				return;
		}

		// throw Error("Request failed.");
	};
}

export const FETCH_ADD_ERROR_MSG = "FETCH_ADD_ERROR_MSG";
export const FETCH_CLEAR_ERROR_MESSAGE = "FETCH_CLEAR_ERROR_MESSAGE";

export function fetchErrorMessage(requestName, message) {
	return (dispatch) => {
		return dispatch({
			type: FETCH_ADD_ERROR_MSG,
			requestName,
			msg: message,
		});
	};
}

export function clearErrorMsg(requestName) {
	return (dispatch) => {
		return dispatch({
			type: FETCH_CLEAR_ERROR_MESSAGE,
			requestName,
		});
	};
}
