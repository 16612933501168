// The action types for the Auth reducer

export const LOGIN_IS_LOADING = "LOGIN_IS_LOADING";
export const LOGIN_HAS_ERROR = "LOGIN_HAS_ERROR";
export const LOGIN_POST_SUCCESS = "LOGIN_POST_SUCCESS";

export const GET_LAUNCHED_CAMPAIGNS_IS_LOADING = "GET_LAUNCHED_CAMPAIGNS_IS_LOADING";
export const GET_LAUNCHED_CAMPAIGNS_HAS_ERROR = "GET_LAUNCHED_CAMPAIGNS_HAS_ERROR";
export const GET_LAUNCHED_CAMPAIGNS_POST_SUCCESS = "GET_LAUNCHED_CAMPAIGNS_POST_SUCCESS";

export const SET_USERID = "SET_USERID";
export const SET_CAMPAIGNID = "SET_CAMPAIGNID";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const UPDATE_JWT_TOKEN = "UPDATE_JWT_TOKEN";

export const LOGOUT_IS_LOADING = "LOGOUT_IS_LOADING";
export const LOGOUT_HAS_ERROR = "LOGOUT_HAS_ERROR";
export const LOGOUT_POST_SUCCESS = "LOGOUT_POST_SUCCESS";

export const AUTH_HAS_ERROR = "AUTH_HAS_ERROR";
