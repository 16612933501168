import React from "react";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { togglePromptModal } from "../../redux/selectedRecipient/actions";

// TODO: The prime button thing can probably just be local state
export const AnswerBox = ({ answers, toggleRadio, selectedAnswerId, togglePromptModal }) => (
	<div className="answer-box message floating-bubble">
		{answers !== undefined &&
			answers.map((p, i) => {
				return p.text === "" ? (
					<div key={p.value} />
				) : (
					<label key={p.value} className="closed-answer">
						<input
							type="radio"
							value={i}
							checked={i === selectedAnswerId}
							onChange={(e) => toggleRadio(e.target.value)}
						/>
						<div className="answer-value">{p.value}</div>
						<div className="answer-text">{p.text}</div>
					</label>
				);
			})}
		<button className="button is-primary mt-4 is-outlined" onClick={() => togglePromptModal(true)}>
			Select Prompt
		</button>
	</div>
);

export default reduxConnectedComponent(AnswerBox, null, {
	togglePromptModal,
});
