import React from "react";
import NotificationsNav from "./NotificationsNav";

export default function RecipientNavBar({ recipientName, leftChildren, rightChildren }) {
	let displayName = "None";
	if (recipientName) {
		/*
          Before the app has time to request to the API, `name` will be undefined
          We take the first name and the first initial of their last name to display
        */
		const splitName = recipientName.split(" ");
		displayName = `${splitName[0]} ${splitName[1] ? `${splitName[1][0]}.` : ""}`;
	}
	return (
		<nav className="navbar" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				<div id="recipient-name" className="navbar-item">
					{displayName}
				</div>
			</div>
			<div className="navbar-start" id="conversation-counts">
				{leftChildren}
			</div>
			<div className="navbar-end">
				<NotificationsNav />
				{rightChildren}
			</div>
		</nav>
	);
}
