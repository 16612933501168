import React from "react";
import AnswerBox from "../components/Answers/AnswerBox";

export const MessageBubble = ({
	messageSource,
	messageContent,
	answerValue,
	sendingFailed,
	mediaId,
	mediaURL,
	answerList = [],
	selectedAnswerId,
	toggleRadio,
	togglePromptModal,
}) => {
	switch (messageSource) {
		case "recipient":
			return (
				<div className="message-incoming-wrapper">
					<div className="message incoming has-text-black">{messageContent}</div>
					{!!answerList.length && (
						<AnswerBox
							answers={answerList}
							toggleRadio={toggleRadio}
							answersAccessible={true}
							selectedAnswerId={selectedAnswerId}
						/>
					)}
				</div>
			);
		case "accepted":
			return (
				<div className="message meta-status answer-coding">
					Accepted:{" "}
					<span className="accepted-answer">
						{messageContent} ({answerValue})
					</span>
				</div>
			);
		case "media":
			return (
				<div className="message outgoing media">
					<img src={mediaURL} alt={mediaId} />
				</div>
			);
		default:
			return <div className={`message outgoing ${sendingFailed ? "sendingFailed" : ""}`}>{messageContent}</div>;
	}
};
