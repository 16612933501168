import SurveyComponent from "./SurveyTool.component";
import {
	updateConversationSender,
	updateConversationAccepted,
	updateConversationRecipient,
	endAndRemoveConversation,
	updatedSelectedRecipientPhone,
	checkPhoneAndAddRecipientMessageToConversation,
	checkPhoneAndTerminateConversation,
	addToPermanentOptOutAndTerminateConversation,
} from "../../redux/recipients/thunks";

import {
	updateConversationNextScript,
	updateSelectedRecipient,
	updateActionableConversationCount,
} from "../../redux/recipients/actions";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { addTexterAppEvent } from "../../redux/events/actions";

export default reduxConnectedComponent(
	SurveyComponent,
	(state) => ({
		permissionlevel: state.auth.permissionlevel,
	}),
	{
		updateConversationSender,
		updateConversationAccepted,
		updateConversationNextScript,
		updateConversationRecipient,
		endAndRemoveConversation,
		updatedSelectedRecipientPhone,
		updateSelectedRecipient,
		updateActionableConversationCount,
		checkPhoneAndAddRecipientMessageToConversation,
		checkPhoneAndTerminateConversation,
		addToPermanentOptOutAndTerminateConversation,
		addTexterAppEvent,
	},
);
