import React from "react";
import { Button } from "../../common/Button";

/**
 * Modal that opens when agent is inactive
 * @param {secondsLeft} integer the number of seconds till automatic logout
 * @param {cancel} function cancel the logout and stay logged in
 * @param {logout} function Logout now and don't wait for countdown
 */
export const InactivityModal = ({ secondsLeft, cancel, logout }) => (
	<div className="dcm-sender-promptserver-modal">
		<p>You are being logged out due to inactivity in {secondsLeft}.</p>
		<div className="div-28">
			<Button onClick={cancel}>Stay Logged In</Button>
			<Button onClick={logout}>Log Out</Button>
		</div>
	</div>
);
