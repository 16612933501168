import * as actionTypes from "./types";

const initialState = {
	queue: [],
};

export default function notificationsReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.ADD_NOTIFICATION:
			// console.log("Adding notification", action);
			// We don't need to create random IDs for notifications,
			//we can just use the UTC time now and add 1 for each new notification. This way we can sort them by time withough having to worry about collisions
			return {
				...state,
				queue: [...state.queue, action.notification],
			};
		case actionTypes.UPDATE_NOTIFICATION:
			return {
				...state,
				queue: state.queue.map((notif) =>
					notif.id === action.notification.id ? { ...notif, ...action.notification } : notif,
				),
			};
		case actionTypes.REMOVE_NOTIFICATION:
			return {
				...state,
				queue: state.queue.filter((notif) => notif.id !== action.id),
			};
		case actionTypes.REMOVE_TAIL:
			return {
				...state,
				queue: state.queue.slice(0, -30),
			};
		default:
			return state;
	}
}
