import React from "react";
import ModalBase, { ModalHeader, ModalBody } from "./ModalBase";

const MessageBubble = ({ message, who, date }) => {
	const acceptedPrefix = who === "accepted" ? "Accepted answer: " : "";

	return (
		<div className={`message-container ${who}`}>
			<div className={`message-bubble ${who}`}>
				{acceptedPrefix} {message}
			</div>
			<div className="message-date">{new Date(date).toLocaleString()}</div>
		</div>
	);
};

const SelectedConversationModal = ({ open, onClose, conversation }) => {
	const messages = (conversation && conversation.number && conversation.number.conversation) || [];
	const conversationName = conversation && conversation.number && conversation.number.name;
	const recipientId = conversation && conversation.recipientId;

	console.log(">>>", conversation);

	return (
		<ModalBase onClose={onClose} open={open} toggleModal={onClose}>
			<ModalHeader>
				{conversationName} ({recipientId})
			</ModalHeader>
			<ModalBody>
				<div className="messages-wrapper">
					{messages.map((msg, index) => (
						<MessageBubble key={index} message={msg.what} who={msg.who} date={msg.date} />
					))}
				</div>
			</ModalBody>
		</ModalBase>
	);
};

export default SelectedConversationModal;
