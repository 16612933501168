import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import auth from "./auth/reducer";
import campaign from "./campaign/reducer";
import recipients from "./recipients/reducer";
import selectedRecipient from "./selectedRecipient/reducer";
import agent from "./agent/reducer";
import inactiveRecipients from "./inactiveComponents/reducer";
import notifications from "./notifications/reducer";
import eventFeed from "./feed/reducer";
import events from "./events/reducer";

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		auth,
		campaign,
		recipients,
		selectedRecipient,
		agent,
		inactiveRecipients,
		notifications,
		events,
		eventFeed,
	});

export default createRootReducer;
