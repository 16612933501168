import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faCircleExclamation, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import {
	addNotification,
	// generateNotificationId,
	updateNotification,
	removeNotification,
	removeTail,
} from "../../redux/notifications/actions";
import { NOTIFICATION_TYPES } from "../../redux/notifications/constants";

function ActionBubbles({ items = [] }) {
	const [activeItems, setActiveItems] = useState(items);

	useEffect(() => {
		// Item has been around for > than 1 second
		if (activeItems.filter((item) => Date.now() - item.id > 1000).length > 5) {
			console.log("Cleanup ran");
			setActiveItems(activeItems.filter((item) => Date.now() - item.id < 1000));
		}
	}, [activeItems]);

	if (activeItems.length > 0) {
		console.log("Items active", activeItems);
	}

	return (
		<div className="">
			{activeItems
				.filter(
					(item) =>
						item.notifType === NOTIFICATION_TYPES.OUTGOING_SCRIPT_SMS ||
						item.notifType === NOTIFICATION_TYPES.ACCEPT_ANSWER ||
						item.notifType === NOTIFICATION_TYPES.INCOMING_SMS,
				)
				.sort((a, b) => a.id - b.id)
				.map((item, index) => {
					let icon;
					switch (item.notifType) {
						case NOTIFICATION_TYPES.OUTGOING_SCRIPT_SMS:
							icon = <FontAwesomeIcon icon={faPaperPlane} className="has-text-warning" />;
							break;
						case NOTIFICATION_TYPES.ACCEPT_ANSWER:
							icon = <FontAwesomeIcon icon={faCheckSquare} className="has-text-success" />;
							break;
						case NOTIFICATION_TYPES.INCOMING_SMS:
							icon = <FontAwesomeIcon icon={faPaperPlane} className="has-text-info" />;
							break;
					}

					return (
						<div
							key={index}
							className={`action-bubble ${Date.now() - item.id < 25 ? "move-up" : ""} ${
								Date.now() - item.id < 150 ? "fade-out" : ""
							}`}
							data-badge={item.count}
							title={item.message}
						>
							{/* {item.message} */}
							{icon}
						</div>
					);
				})}
		</div>
	);
}

export default reduxConnectedComponent(ActionBubbles, (state) => ({
	items: state.notifications.queue,
}));
