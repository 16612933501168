import React, { useState, useEffect } from "react";

import SkipConversation from "../Skip/SkipConversation";
import TerminateNav from "./TerminateNav";
import PermanentOptOutNav from "./PermanentOptOutNav";

export default function UserMenu({ phone, endAndRemoveConversation, addToPermanentOptOutAndTerminateConversation }) {
	const [isDropdown, setIsDropdown] = useState(true);

	useEffect(() => {
		document.getElementById("recipient-name").addEventListener("click", () => {
			setIsDropdown(!isDropdown);
		});
	}, [isDropdown]);

	return (
		<>
			<TerminateNav
				className="navbar-item"
				currentPhone={phone}
				endAndRemoveConversation={endAndRemoveConversation}
			/>
			<SkipConversation phone={phone} />
			<PermanentOptOutNav
				currentPhone={phone}
				addToPermanentOptOutList={addToPermanentOptOutAndTerminateConversation}
				className="navbar-item"
			/>
		</>
	);
}
