import React from "react";
import ActionWithConfirmationNav from "./ActionWithConfirmationWrapper";

export default function PermanentOptOutNav({ currentPhone, addToPermanentOptOutList, className = "" }) {
	return (
		<ActionWithConfirmationNav
			className={className}
			currentPhone={currentPhone}
			actionToConfirm={addToPermanentOptOutList}
			actionText="Permanently Opt-out"
			confirmationModal={{
				headerText: "Permanent Opt Out",
				bodyText:
					"Are you sure you want to permanently opt out this individual? You cannot reverse this action and they will be permanently opted out from all future communication",
				confirmText: "Yes",
			}}
		/>
	);
}
