import React from "react";
import { Button } from "../common/Button";
import AgentStatisticsView from "./UserStatistics.redux";
// import ScriptErrorEvents from "./ScriptErrorEvents";
import AgentTimeLogCheckerContainer from "./AgentTimeLogManager/AgentTimeLogIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";

/**
 * The left sidebar of the texter app
 * @param {object} props React props
 * @param {function} props.logoutThunkCallback Redux thunk function
 * @param {string} props.selectedCampaignName Current campaign string name
 * @param {"active" | "sandbox"} props.campaignStatus Current campaign status
 * @param {number} props.campaignid Current campaign integer ID
 * @param {string} props.userid Current user string ID
 * @param {string} props.newRecordsMsg Warning msg when records are limited by hour
 * @returns {JSX.Element}
 */
export const StatusBar = ({
	logoutThunkCallback,
	selectedCampaignName,
	campaignStatus,
	campaignid,
	userid,
	newRecordsMsg,
}) => {
	return (
		<div className="status-bar is-flex is-flex-direction-column is-flex- p-3">
			<div className="dcm-topbar-brand">
				<img src="/s160_logo.png" alt="Survey160" />
			</div>
			{/* <div className="events p-0">
        <ScriptErrorEvents />
      </div> */}
			<div className="dcm-topbar-left dcm-topbar-campaign-info">
				{campaignStatus !== "sandbox" && <AgentTimeLogCheckerContainer />}

				<p className="mb-2">
					<b>Survey:</b>{" "}
					<span className="campaign">
						{selectedCampaignName} ({campaignid}){" "}
					</span>
				</p>

				{campaignStatus === "sandbox" && (
					<p className="mb-2">
						<b>Survey Status:</b> <span className="sandbox">Sandbox</span>
					</p>
				)}

				{campaignStatus === "active" && (
					<p className="mb-2">
						<b>survey status:</b> <span className="active">Active</span>
					</p>
				)}
				<p>
					<b>User:</b> <span className="userid">{userid}</span>
				</p>
				{newRecordsMsg && (
					<p className="mb-2">
						<br />
						<b>Hourly limit active:</b>{" "}
						<span>
							{newRecordsMsg} <FontAwesomeIcon icon={faHourglass} />
						</span>
					</p>
				)}
			</div>
			<AgentStatisticsView />
			<div className="dcm-topbar-right">
				<Button onClick={logoutThunkCallback} classOverride="button is-danger is-outlined">
					Log Out
				</Button>
			</div>
		</div>
	);
};
