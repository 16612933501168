import React from "react";
import { connect } from "react-redux";
import PromptModal from "./PromptModal";
import { togglePromptModal } from "../../redux/selectedRecipient/actions";
import { bindActionCreators } from "redux";

const PromptModalContainer = (props) => <PromptModal {...props} />;

const mapStateToProps = (state) => ({
	open: !!state.selectedRecipient.promptModalVisible,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ togglePromptModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PromptModalContainer);
