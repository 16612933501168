import React from "react";

import UserMenuItem from "../ConversationTopBar/UserMenuItem";
import { setTimeoutAndStoreId } from "../../util/helper";
import { setConversationSkipped } from "../../redux/recipients/actions";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { addFeedEvent, getFeedEvent } from "../../redux/feed/actions";
import { FeedEventType } from "../../redux/feed/constants";

const SKIP_CONVERSATION_TIMEOUT_DELAY = 10000;

/**
 * @callback SetSkippedAction
 * @param {string} phone
 * @param {boolean} skipped
 */

/**
 * @callback CloseMenuCallback
 */

/**
 * @typedef Props
 * @type {object}
 * @property {string} phone
 * @property {SetSkippedAction} setConversationSkipped
 * @property {function} addFeedEvent
 * @property {CloseMenuCallback} closeMenu
 * @property {boolean} skipped
 */

/**
 * Handles the skip functionality by setting a conversation to skipped, then creating a timeout which
 * resets it to active after a wait.
 * @param {Props} props
 */
export function SkipConversation({ phone, setConversationSkipped, addFeedEvent, skipped }) {
	function resetConversationSkipped() {
		setConversationSkipped(phone, false);
	}

	function onSkip() {
		// Set convo skipped
		setConversationSkipped(phone, true);

		// Reset it in 10 seconds
		setTimeoutAndStoreId(resetConversationSkipped, SKIP_CONVERSATION_TIMEOUT_DELAY, `skip-question-${phone}`);
	}

	// Disable button if already skipped
	return (
		<UserMenuItem onClick={onSkip} disabled={skipped} id="skip-question-button">
			Skip Conversation
		</UserMenuItem>
	);
}

// If there are no new convos then we want to render the conversation while skipped
// the state will allow us to deactivate the button when the conversation is skipped

export default reduxConnectedComponent(
	SkipConversation,
	(state) => ({
		skippedPhones: state.recipients.skipped,
	}),
	{
		setConversationSkipped,
		addFeedEvent,
	},
);
