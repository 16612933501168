import { globalHTTPResponseHandler } from "../redux/FetchResponseManager/actions";
import { NGROK_ENABLED, SERVER_URLROOT } from "../util/constants";
import { updateJWTToken } from "../redux/auth/actions";

/**
 * Dispatch to create a fetch
 */
export function fetchRequest(name, method, url, params) {
	return {
		type: "FETCH",
		name: name,
		method: method,
		url: url,
		params: params,
	};
}

export const fetchMiddleware = (fetchImplementation) => (store) => (next) => (action) => {
	if (action.type === "FETCH") {
		const { params, url, name, method } = action;

		const myHeaders = new Headers();
		const dispatch = store.dispatch;

		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("accept", "application/json");
		myHeaders.append("Authorization", store.getState().auth.jwt);
		if (NGROK_ENABLED) myHeaders.append("ngrok-skip-browser-warning", "1");

		const init = {
			method: method,
			headers: myHeaders,
			body: method == "GET" ? null : JSON.stringify(params),
		};

		dispatch({
			type: name + "_IS_LOADING",
		});

		return (
			fetchImplementation(SERVER_URLROOT + url, init)
				// Update the JWT access token (if it has been refreshed)
				.then((response) => {
					let authHeader = response.headers.entries().find((e, i) => e[0] === "authorization");
					let token = authHeader ? authHeader[1].slice("Bearer ".length) : null;

					// To debug token refresh, uncomment this line, and set a 10sec ttl for the jwt_token in restpoint/webtool.py (line ~835)
					// console.log({ "URL:": url, "Token: ": `...${token.slice(-10)}`});

					if (token) {
						const currentToken = store.getState().auth.jwt;
						if (token !== currentToken) {
							dispatch(updateJWTToken(token));
						}
					}
					return response;
				})

				.then((response) => {
					dispatch(globalHTTPResponseHandler(response, name));

					if (!response.ok) {
						dispatch({
							type: name + "_HAS_ERROR",
							status: response.status,
							message: response.statusText,
						});
						throw Error(response.statusText);
					}
					return response;
				})
				.then((response) => response.json())
				.then((data) => {
					dispatch({
						type: name + "_" + method + "_SUCCESS",
						data: data,
					});
					return data;
				})
				.catch((error) => {
					console.error(error);
				})
		);
	}
	return next(action);
};
