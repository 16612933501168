import * as actionTypes from "./types";

/**
 * Generates a unique notification ID based on the UTC time.
 * @returns {number} The generated notification ID.
 */
export const generateNotificationId = (() => {
	let lastId = Date.now();
	return () => ++lastId;
})();

/**
 * Adds a new notification.
 * @param {Object} notification - The notification object to be added.
 * @returns {Function} A Redux thunk function.
 */
export function addNotification(notification) {
	return (dispatch) =>
		dispatch({
			type: actionTypes.ADD_NOTIFICATION,
			notification,
		});
}

/**
 * Updates an existing notification.
 * @param {number} id - The ID of the notification to be updated.
 * @param {Object} notifUpdates - The updated properties of the notification.
 * @param {string} notifUpdates.message - The updated message of the notification.
 * @param {string} notifUpdates.status - The updated status of the notification.
 * @returns {Function} A Redux thunk function.
 */
export function updateNotification(id, notifUpdates) {
	return (dispatch) =>
		dispatch({
			type: actionTypes.UPDATE_NOTIFICATION,
			notification: {
				id,
				...notifUpdates,
			},
		});
}

/**
 * Removes a notification.
 * @param {number} id - The ID of the notification to be removed.
 * @returns {Function} A Redux thunk function.
 */
export function removeNotification(id) {
	return (dispatch) =>
		dispatch({
			type: actionTypes.REMOVE_NOTIFICATION,
			id,
		});
}

export function removeTail() {
	return (dispatch) =>
		dispatch({
			type: actionTypes.REMOVE_TAIL,
		});
}
