import { fetchRequest } from "../../middleware/fetchMiddleware";

export const send = (phone, currentscriptid, what, type, isScriptQuestion, s160MediaId) => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest("SEND", "POST", `/texter/campaign/${state.auth.campaignid}/recipient/${phone}/send`, {
			currentscriptid,
			type,
			what,
			isScriptQuestion,
			s160MediaId,
		}),
	);
};

export const accept = (phone, currentscriptid, what, type, value, nextscriptid) => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest("ACCEPT", "POST", `/texter/campaign/${state.auth.campaignid}/recipient/${phone}/accept`, {
			currentscriptid,
			type,
			what,
			value,
			nextscriptid,
		}),
	);
};

export const bot = (phone, currentscriptid, what) => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest("BOT", "POST", `/texter/campaign/${state.auth.campaignid}/recipient/${phone}/bot`, {
			currentscriptid,
			what,
		}),
	);
};
