import { getCurrentQuestion } from "../redux/recipients/selectors";
import { evaluateConditionals } from "./scriptLogic";

/**
 * Get's the next question to send given the current script, transcript and variables
 * @param {Array<Object>} script
 * @param {Object} variables
 * @param {Array<Object>} transcript
 * @param {string | null} scriptid
 * @returns {string} The currentscriptid
 */
export function getConversationNextScript(script, variables, transcript, scriptid) {
	// Use scriptid if passed, or the state value, or finally the first id in the script
	let curQId = script[0].id;
	if (scriptid) {
		curQId = scriptid;
	}
	// else if (recipients[phone].currentscriptid) { curQId = recipients[phone].currentscriptid; }

	// Technically a selector, but actually just searches the script for the question
	const curQ = getCurrentQuestion(script, curQId);

	// Every question has a default next, so this should always be skipped
	// Except for a terminating question
	if (!curQ.defaultNext && script[script.length - 1].id !== curQId) {
		curQ.defaultNext = script[script.findIndex((q) => q.id === curQId) + 1].id;
	}

	// NOTE: if the last question isn't an accepted value then this isn't getting applied. ( which begs the question of why the hell it's running at all?)
	if (
		!["closing", "terminating"].includes(curQ.type) &&
		transcript.find((item) => item.currentscriptid === curQId) === undefined
	) {
		// Only log accepted values since that's the only time we skip to a new question
		return curQId;
	}

	return evaluateConditionals(curQ.conditionalNexts, variables, transcript, curQ.defaultNext);
}
