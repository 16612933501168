import { fetchRequest } from "../../middleware/fetchMiddleware";
import {
	BOT_RESPONSE_DELAY,
	BOT_RESPONSE_PHRASES,
	TERMINATING_QUESTION_TYPES,
	TERMINATING_QUESTION_TYPES_STATUS_MAP,
	TERMINATION_DELAY,
	TOTAL_PHONE,
	DISABLE_NO_BOT_RESPONSE,
} from "../../util/constants";
import { isFollowup, replaceMiscVarsWithValues, setTimeoutCustom } from "../../util/helper";
import * as actionTypes from "./types";
import {
	getConversationNextScript,
	getRecipientTranscript,
	getCurrentQuestion,
	getScript,
	getNumberList,
	getRecipient,
} from "./selectors";
import {
	addItemToConversation,
	removeConversation,
	removeLastConversationItem,
	selectNextRecipient,
	setConversationTerminating,
	toggleRecipientReattempt,
	updateActionableConversationCount,
	updateConversationNextScript,
	updateConversationLoading,
	updateLastTextSent,
} from "./actions";
import { EVENT_TYPES, addTexterAppEvent } from "../events/actions";
import { ScriptLogicError } from "../../util/scriptLogic";
import {
	addNotification,
	updateNotification,
	removeNotification,
	generateNotificationId,
} from "../notifications/actions";
import { NOTIFICATION_TYPES } from "../notifications/constants";
import { addFeedEvent, getFeedEvent } from "../feed/actions";
import { FeedEventType } from "../feed/constants";
import { accept, bot, send } from "./convo_thunks_v2";

export function addToPermanentOptOutAndTerminateConversation(phoneNumber) {
	return (dispatch) => {
		return dispatch(
			fetchRequest(actionTypes.ADD_TO_PERMANENT_OPTOUT_PREFIX, "POST", "/optout/addNumber", { phoneNumber }),
		)
			.then(() => {
				dispatch(
					// I'm not sure which one of these is the correct one
					// checkPhoneAndTerminateConversation(phoneNumber)
					endAndRemoveConversation(phoneNumber, "opted-out", "opted-out"),
				);
				dispatch(addFeedEvent(getFeedEvent(FeedEventType.PERMANENT_OPTOUT, phoneNumber)));
			})
			.catch((error) => {
				// TODO: Implement error notifications, and trigger one here
				// Bubble the error to handle it at the component level for now
				console.error("Error adding to permanent optout", error);
				throw error;
			});
	};
}

export function getActiveLimboList(limit = 50) {
	return (dispatch, getState) => {
		const state = getState();
		return dispatch(
			fetchRequest(
				actionTypes.GET_ACTIVE_LIMBO_LIST_PREFIX,
				"GET",
				`/texter/campaign/${state.auth.campaignid}/list/limbo?limit=${limit}`,
			),
		).then((data) => {
			if (data && data.data) {
				const len = Object.keys(data.data).length;
				if (len !== 0) {
					// feedEvent. loaded more numbers (count)
					const e = getFeedEvent(FeedEventType.NEW_NUMBERS_LOADED, "");
					e.message += `(${len})`;
					dispatch(addFeedEvent(e));
				}
			}
			return data;
		});
	};
}

export const getMyList = () => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest("GET_MY_LIST", "POST", "/getMyList", {
			campaignid: state.auth.campaignid,
			userid: state.auth.userid,
		}),
	);
};

export const getMoreList = () => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest("GET_MORE_LIST", "POST", "/getMoreList", {
			campaignid: state.auth.campaignid,
			userid: state.auth.userid,
			total: TOTAL_PHONE,
		}),
	).then((data) => {
		if (data && data.data) {
			const len = Object.keys(data.data).length;
			if (len !== 0) {
				// feedEvent. loaded more numbers (count)
				const e = getFeedEvent(FeedEventType.NEW_NUMBERS_LOADED, "");
				e.message += `(${len})`;
				dispatch(addFeedEvent(e));
			} else if (data.reachedLimit) {
				dispatch(addFeedEvent(getFeedEvent(FeedEventType.REACHED_HOURLY_LIMIT, "")));
			} else {
				dispatch(addFeedEvent(getFeedEvent(FeedEventType.NO_NEW_NUMBERS, "")));
			}
		}
		return data;
	});
};

export const endConversation = (phone, method, disposition) => (dispatch, getState) => {
	const state = getState();
	const script = state.campaign.script;
	const numbers = state.recipients.numbers;
	const currentId = numbers[phone].currentscriptid ? numbers[phone].currentscriptid : script[0].id;

	const currentQuestion = script.find((item) => item.id === currentId);
	let table = "phonelist";
	if (method === "panel") {
		table = "panel";
		// TODO: Should this be "closed"???
		method = "complete";
	}

	// When convo in panel make sure we use the right table
	if (currentQuestion && currentQuestion.type.includes("panel")) {
		table = "panel";
	}

	return dispatch(
		fetchRequest(actionTypes.END_CONVERSATION_PREFIX, "POST", "/texter/endConversation", {
			campaignid: parseInt(state.auth.campaignid),
			userid: state.auth.userid,
			phone,
			method,
			table,
			disposition,
		}),
	);
};

export const addRecipientToPanel = (phone) => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest(actionTypes.ADD_RECIPIENT_TO_PANEL_PREFIX, "POST", "/addRecipientToPanel", {
			campaignid: state.auth.campaignid,
			userid: state.auth.userid,
			phone,
		}),
	);
};

/**
 * Checks whether the given phone is handled by this agent.
 * @param {*} phone
 * @param {*} blob
 * @returns
 */
export const checkPhoneAndAddRecipientMessageToConversation = (phone, whatWasSaid) => (dispatch, getState) => {
	const state = getState();
	const phoneInList = phone in getNumberList(state);
	// console.log("is phone in list?", phone, phoneInList);
	if (phoneInList) {
		dispatch(
			updateConversationRecipient(
				phone,
				whatWasSaid,
				// NOTE: The current version of this
				getRecipient(state, phone).currentscriptid,
				{},
				new Date(),
			),
		);
		// Add feedEvent for incoming
		addFeedEvent(getFeedEvent(FeedEventType.INCOMING_SMS, phone));
	}
};

/**
 * Simple wrapper to terminateConversation which checks whether the given phone is handled by this agent.
 * @param {*} phone
 * @returns
 */
export const checkPhoneAndTerminateConversation = (phone) => (dispatch, getState) => {
	const state = getState();
	const phoneInList = phone in getNumberList(state);
	if (phoneInList) {
		dispatch(removeConversation(phone));

		// Add feedEvent for incoming
		dispatch(addFeedEvent(getFeedEvent(FeedEventType.AUTO_TERMINATED, phone)));
	}
};

// NOTE: I'm not sure what the difference is between `method` and `disposition`
/**
 * FINISH conversation means setting the final status and making a
 * request to the server.
 * @param {Number} phone The recipient phone number
 * @param {String} method The method to end the conversation with
 * @param {String} disposition The disposition to end the conversation with
 * @returns
 */
export const endAndRemoveConversation = (phone, method, disposition) => async (dispatch) => {
	try {
		dispatch(endConversation(phone, method, disposition));
		if (method === TERMINATING_QUESTION_TYPES_STATUS_MAP.terminating.method) {
			dispatch(addFeedEvent(getFeedEvent(FeedEventType.TERMINATED, phone)));
		}
		await dispatch(removeConversation(phone));
	} catch (error) {
		console.error("Error terminating conversation", error);
		throw error;
	}
};

export const updatedSelectedRecipientPhone = () => (dispatch) => {};

/**
 * For incoming messages theres no need hit the API
 * @param {*} phone
 * @param {*} whatWasSaid
 * @param {*} currentQuestionId
 * @param {*} misc
 * @param {*} date
 * @returns
 */
export const updateConversationRecipient = (phone, whatWasSaid, currentQuestionId, misc, date) => (dispatch) => {
	const item = {
		who: "recipient",
		what: whatWasSaid,
		date: date ? date : Date(),
		...misc,
	};

	dispatch(addItemToConversation(phone, item, currentQuestionId));
	dispatch(updateActionableConversationCount());
};

export const updateConversationSender = (phone, whatWasSaid, currentQuestionId) => (dispatch, getState) => {
	const state = getState();
	const script = getScript(state);
	const currentQuestion = getCurrentQuestion(script, currentQuestionId);

	const isScriptQuestion = currentQuestionId ? true : false;
	const questionType = currentQuestion.type;
	const table = questionType === "panel" ? "panel" : "phonelist";

	// Only send MMS on script messages, not on folloups
	const convo = getRecipientTranscript(state, phone);
	const includeMedia = !isFollowup(convo, currentQuestionId);

	const item = {
		who: "sender",
		// `what` will be null if the message is a terminating message
		what: whatWasSaid || "",
		// True if this is a script question
		scriptboolean: isScriptQuestion,
		date: Date(),
		currentscriptid: currentQuestionId,
		type: questionType,
		s160MediaId: includeMedia ? currentQuestion.s160MediaId : undefined,
	};

	const notificationId = generateNotificationId();
	dispatch(
		addNotification({
			message: "Sending message to " + phone,
			id: notificationId,
			status: "pending",
		}),
	);
	dispatch(addFeedEvent(getFeedEvent(FeedEventType.OUTGOING_SCRIPT_SMS, phone)));

	// NOTE: This enables autoselecting the next conversation on send
	dispatch(addItemToConversation(phone, item, currentQuestionId, true));
	dispatch(selectNextRecipient());

	return dispatch(
		// update conversation in our backend api
		send(
			phone,
			currentQuestionId,
			whatWasSaid,
			questionType,
			isScriptQuestion,
			includeMedia ? currentQuestion.s160MediaId : undefined,
		),
		// appendToConversation(phone, currentQuestionId, item, table)
	)
		.then((data) => {
			if (!data) {
				// FIX: Add indication of failure to the conversation item instead of the following line
				// item.sendingFailed = true;
				throw new Error("Error sending message to " + phone);
			}

			// Update count with every action!
			dispatch(updateActionableConversationCount());
			dispatch(
				updateNotification(notificationId, {
					status: "success",
					message: "Message sent.",
				}),
			);
			dispatch(updateConversationLoading(phone, false));

			// Remove the notification after 5 seconds
			setTimeout(dispatch, 5e3, removeNotification(notificationId));

			if (TERMINATING_QUESTION_TYPES.includes(questionType)) {
				dispatch(removeConversation(phone));
				switch (questionType) {
					case "terminated":
						dispatch(addFeedEvent(getFeedEvent(FeedEventType.TERMINATED, phone)));
					case "closing":
						dispatch(addFeedEvent(getFeedEvent(FeedEventType.CLOSED, phone)));
				}
			} else {
				dispatch(toggleRecipientReattempt(phone, false));
				dispatch(updateLastTextSent());
				// NOTE: This enables autoselecting the next conversation on send
				// dispatch(selectNextRecipient());

				if (state.campaign.active === "sandbox") {
					return dispatch(sendBotResponse(phone, currentQuestionId));
				}
			}
		})
		.catch((error) => {
			dispatch(
				updateNotification(notificationId, {
					status: "error",
					message: "Failed to send message.",
				}),
			);

			console.error("Failed to send message", error);
			// Remove the notification after 15 seconds to give the agents time to see the error
			setTimeout(dispatch, 15e3, removeNotification(notificationId));
			dispatch(removeLastConversationItem(phone));
			dispatch(updateConversationLoading(phone, false));
		});
};

export const sendBotResponse = (phone, currentQuestionId) => (dispatch, getState) => {
	// If the conversation is already terminating then we shouldn't do this:
	const currentQuestionType = getCurrentQuestion(getScript(getState())).type;

	if (TERMINATING_QUESTION_TYPES.includes(currentQuestionType)) {
		console.log("Terminating, so skipping the BOT response");
		return;
	}

	const botConfigAdjustment = Boolean(DISABLE_NO_BOT_RESPONSE) ? 1 : 0;
	const index = Math.floor(Math.random() * (BOT_RESPONSE_PHRASES.length + botConfigAdjustment));

	if (index == BOT_RESPONSE_PHRASES.length) {
		console.log("Skipping BOT response");
		return;
	}

	// This return is ugly. I'm not sure this is the best way to handle this
	return new Promise((resolve, reject) => {
		setTimeoutCustom(() => {
			dispatch(updateConversationBot(phone, BOT_RESPONSE_PHRASES[index], currentQuestionId))
				.then(resolve)
				.catch(reject);
		}, BOT_RESPONSE_DELAY);
	});
};

export const updateConversationBot = (phone, whatWasSaid, currentQuestionId, misc, date) => (dispatch) => {
	const item = {
		who: "recipient",
		what: whatWasSaid,
		date: date ? date : Date(),
		currentscriptid: currentQuestionId,
		...misc,
	};

	dispatch(addItemToConversation(phone, item, currentQuestionId));

	dispatch(addFeedEvent(getFeedEvent(FeedEventType.BOT_RESPONSE, phone)));

	return dispatch(bot(phone, currentQuestionId, whatWasSaid))
		.then((data) => {
			if (!data) {
				throw new Error("Failed to send bot response");
			}
			// dispatch(addItemToConversation(phone, item, currentQuestionId));
		})
		.catch((error) => {
			console.error("Failed to send bot response", error);
			dispatch(removeLastConversationItem(phone));
			dispatch(updateActionableConversationCount());
		});
};

/**
 * New method to not double responibility for the recipient method
 * @param {*} phone
 * @param {*} whatWasSaid
 * @param {*} currentQuestionId
 * @param {Object} misc Blob representing the accepted answer info -->
 *    {
 *      value: acceptedValue,
 *      id: pendingID,
 *      type: answerType,
 *    }
 * @param {*} date
 * @returns {Promise<String|null>} Promise resolving to the next question id or null if the conversation is terminated
 */
export const updateConversationAccepted =
	(phone, whatWasSaid, currentQuestionId, misc, date) => (dispatch, getState) => {
		const item = {
			who: "accepted",
			what: whatWasSaid,
			date: date ? date : Date(),
			currentscriptid: currentQuestionId,
			...misc,
		};

		const state = getState();

		// If we have a scriptid then get the question type and include it into the blob
		// const script = thisthis.state.selectedCampaignScript;
		if (currentQuestionId) {
			const script = getScript(state);
			const currentQuestion = getCurrentQuestion(script, currentQuestionId);
			if (currentQuestion) {
				item["type"] = currentQuestion.type;
			}
		}

		const table = getRecipient(state, phone).optIn ? "panel" : "phonelist";

		// Get the next id
		let nextId;
		try {
			nextId = getConversationNextScript(
				state,
				phone,
				[...getRecipientTranscript(state, phone), item],
				currentQuestionId,
			);
		} catch (e) {
			// If there's a script-error dispatch it so we can display it
			// throw e;
			if (e instanceof ScriptLogicError) {
				const errInfo = {
					position: e.position,
					statement: e.statement,
					parsedCondition: e.parsedCondition,
					currentscriptid: e.currentscriptid,
					errorType: e.errorType,
				};
				return dispatch(addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "accept_answer", errInfo));
			}
			return dispatch(addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "accept_answer"));
		}

		item["nextscriptid"] = nextId;

		dispatch(addItemToConversation(phone, item, currentQuestionId, true));
		dispatch(selectNextRecipient());

		dispatch(addFeedEvent(getFeedEvent(FeedEventType.ACCEPT_ANSWER, phone)));

		return dispatch(
			// appendToConversation(phone, currentQuestionId, item, table)
			accept(phone, currentQuestionId, whatWasSaid, item.type, `${item.value}`, nextId),
		)
			.then((data) => {
				if (!data) {
					throw new Error("Failed to accept answer");
				}
				dispatch(updateActionableConversationCount());

				if (data.terminated) return null;

				dispatch(updateConversationNextScript(phone));
				return nextId;
			})
			.catch((error) => {
				console.error("Failed to accept answer", error);
				dispatch(removeLastConversationItem(phone));
			});
	};

/**
 * API request for adding item to a recipient conversation
 * @returns {Promise}
 */
export const appendToConversation = (phone, currentscriptid, messageBlob, table) => (dispatch, getState) => {
	const state = getState();
	return dispatch(
		fetchRequest(actionTypes.APPEND_TO_CONVERSATION_PREFIX, "POST", "/appendToConversation", {
			item: messageBlob,
			currentscriptid,
			campaignid: state.auth.campaignid,
			phone: phone,
			table: table,
			status: state.campaign.active, // TODO: remove me
			userid: state.auth.userid,
		}),
	);
};

/**
 * Wraps getMoreList with check for lastRun and limit reached
 * @param {number} maxInterval The max interval before which to run getMoreList
 * @returns
 */
export function getMoreListHourlyLimit() {
	return function (dispatch, getState) {
		const { reachedAssignmentLimit } = getState().recipients;

		// console.group("Get More List");
		// console.log("Reached assignment limit", reachedAssignmentLimit);
		// console.log("Will run:", reachedAssignmentLimit ? "YES" : "NO");
		// console.groupEnd();

		if (reachedAssignmentLimit) {
			return dispatch(getMoreList());
		}
	};
}
