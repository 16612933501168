import React, { useEffect, useState } from "react";
import { reduxConnectedComponent } from "../util/reduxConnectedHOC";
import "../styles/scripterror.css";
import { setEventVisibility } from "../redux/events/actions";

// TODO: The prime button thing can probably just be local state
export const ScriptErrorEvents = ({ events = [] }) => {
	let [open, setOpen] = useState(false);

	return (
		<div className={`script-error-events-icon ${open ? "open" : "closed"} ${events.length > 0 ? "active" : ""}`}>
			<a onClick={() => setOpen(!open)}>Events({events.length})</a>
			<div className={`events-menu ${open ? "open" : "closed"}`}>
				<ScriptErrorEventsList events={events} />
			</div>
		</div>
	);
};

export const ScriptErrorEventsList = ({ events = [] }) => {
	let [hiddenEvents, sethiddenEvents] = useState(events.map((e) => e.id));

	function showEvent(id) {
		sethiddenEvents(
			hiddenEvents.filter((eventID) => {
				return eventID !== id;
			}),
		);
	}

	function hideEvent(id) {
		sethiddenEvents([...hiddenEvents, id]);
	}

	let acceptAnswerEvents = events
		.filter((event) => event.sourceLocation === "accept_answer" || event.sourceLocation === "init_conditional")
		.map((event, i) => {
			let click, caretSymbol;
			return (
				<ScriptErrorListItem
					open={!hiddenEvents.includes(event.id)}
					event={event}
					showEvent={showEvent}
					hideEvent={hideEvent}
					key={`seli-key-${i}`}
				/>
			);
		});

	return (
		<div className="script-error-list">
			{acceptAnswerEvents.length === 0 && <p className="no-events-text">No events</p>}
			<ul>{acceptAnswerEvents}</ul>
		</div>
	);
};

export function ScriptErrorListItem({ event, open, showEvent, hideEvent }) {
	return (
		<li
			onClick={(e) => {
				e.preventDefault();
				if (open) {
					hideEvent(event.id);
				} else {
					showEvent(event.id);
				}
			}}
		>
			<div>
				<div>
					<b>{event.eventType.toUpperCase()}</b>: {event.errorType}
					<span className="caret">{open ? "⌄" : "^"}</span>
				</div>
				<div className={open ? "" : "collapsed"}>
					<br />
					{event.currentscriptid && (
						<React.Fragment>
							Script ID:<b> {event.currentscriptid}</b>
							<br />
						</React.Fragment>
					)}
					{event.phone && (
						<React.Fragment>
							Source convo:<b> {event.phone}</b>
							<br />
						</React.Fragment>
					)}
					Conditional:<b> {event.parsedCondition}</b>
					<br />
					Statement:<b> {event.statement}</b>
					<br />
				</div>
			</div>
		</li>
	);
}

const POPUP_TIMEOUT = 1500;

const ScriptErrorEventPopupComponent = ({ events = [] }) => {
	let [shownEvents, setShownEvents] = useState(events.map((e) => e.id));

	useEffect(() => {
		const timeouts = [];

		events.forEach((event) => {
			if (!shownEvents.includes(event.id)) {
				const timeout = setTimeout(() => {
					setShownEvents([...shownEvents, event.id]);
				}, POPUP_TIMEOUT); // 5 seconds
				timeouts.push(timeout);
			}
		});

		return () => {
			timeouts.forEach((timeout) => clearTimeout(timeout));
		};
	}, [events, shownEvents]);

	return (
		<div className="script-error-popup">
			{events
				.filter((e) => !shownEvents.includes(e.id))
				.map((event) => {
					return (
						<div className="script-error-popup-notif">
							<b>{event.eventType.toUpperCase()}</b>: {event.errorType}
						</div>
					);
				})}
		</div>
	);
};

const ScriptErrorEventPopupContainer = reduxConnectedComponent(
	ScriptErrorEventPopupComponent,
	(state) => ({
		events: state.events.events,
	}),
	{
		setEventVisibility: (id, visible) => setEventVisibility(id, visible),
	},
);

const ScriptErrorEventsContainer = reduxConnectedComponent(
	ScriptErrorEvents,
	(state) => ({
		events: state.events.events,
	}),
	{
		setEventVisibility: (id, visible) => setEventVisibility(id, visible),
	},
);

export { ScriptErrorEventsContainer as default, ScriptErrorEventPopupContainer };
