import React, { Component } from "react";
import MainComponent from "./Main.component";
import { getCampaignEssentials } from "../../redux/campaign/actions";
import { logout } from "../../redux/auth/actions";

import { getMoreList, getMyList } from "../../redux/recipients/thunks";
import { Link, Redirect } from "react-router-dom";
// import AgentTimeLogManager from "../../components/AgentTimeLogManager/AgentTimeLogManager";
import { getCookies, removeCookieList } from "../../util/helper";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { ACTIONABLE_THRESHOLD } from "../../util/constants";

export class MainContainer extends Component {
	componentDidMount() {
		const { auth, setAuthData } = this.props;
		if (!auth.jwt || auth.jwt === "" || auth.jwt === "crap") {
			const { userid, jwt, campaignid, permission: permissionlevel } = getCookies();

			if (!jwt || (jwt !== "" && jwt !== "crap")) {
				// console.log("I ran", jwt, userid, campaignid);
				// The cookies are OK and we're in.
				let cid = 0;
				try {
					cid = parseInt(campaignid);
				} catch (e) {
					console.error("Campaignid str is invalid", e.message);
				}
				setAuthData(jwt, userid, cid | campaignid, permissionlevel);

				return;
			}
			// The cookies are bad. Logout.
			removeCookieList(["senderjwt", "userid", "campaignid", "permission"]);
		}

		// Get campaign data
		this.props.getCampaignEssentials();
		// Get already assigned list
		this.props.getMyList();
	}

	componentDidUpdate() {
		const { essentials, actionablethreshold } = this.props.campaign;
		const { getMyList, getMoreList, listLoaded, ...recipients } = this.props.recipients;
		const loading = getMyList.loading || essentials.loading || getMoreList.loading;
		const error = getMyList.error || essentials.error || getMoreList.error;

		// If the list is loading or there are already available numbers don't load more
		const threshold = actionablethreshold ? actionablethreshold : ACTIONABLE_THRESHOLD;

		if (loading || error || !recipients.assignedListLoaded || recipients.noNewNumbers) {
			return;
		}

		if (
			(!Object.keys(recipients.numbers).length && !recipients.noNewNumbers) ||
			recipients.actionableCount < threshold
		) {
			this.props.getMoreList();
		}
	}

	render() {
		const { getMyList, getMoreList, ...recipients } = this.props.recipients;
		const { essentials } = this.props.campaign;
		const { jwt, campaignid, userid } = this.props.auth;

		const loadingInit = getMyList.loading || essentials.loading;
		const errorInit = getMyList.error || essentials.error;

		const loading = getMoreList.loading;
		const error = getMoreList.error;

		if ((!jwt || !campaignid || !userid) && !loading && !error) {
			return <Redirect to="/login" />;
		}

		return (
			<div>
				{(!loadingInit && !errorInit && recipients.numbers) || loading ? (
					<MainComponent
						{...recipients}
						{...this.props.campaign}
						campaignStatus={this.props.campaign.active}
						jwt={jwt}
						campaignid={campaignid}
					/>
				) : (
					<div>
						<h1 className="header is-1 ">
							There are no records left for this campaign. Return to{" "}
							<a onClick={this.props.logout}>login</a>.
						</h1>
					</div>
				)}
			</div>
		);
	}
}

export const ConnectedMain = reduxConnectedComponent(
	MainContainer,
	(state) => ({
		campaign: state.campaign,
		recipients: state.recipients,
		auth: state.auth,
	}),
	{ getCampaignEssentials, getMyList, getMoreList, logout },
);
