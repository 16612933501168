import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../common/Button";

const BUTTON_TYPE_COLOR_MAP = {
	"acc&send": "is-success",
	send: "is-primary",
	accept: "is-warning",
	notSelected: "is-danger",
};

export function BottomPanelActionButton({
	buttonType,
	buttonAction,
	disabled = false,
	children,
	className = "",
	tooltip = "",
}) {
	return (
		<Button
			classOverride={`button ${buttonType ? BUTTON_TYPE_COLOR_MAP[buttonType] : ""} ${className}`}
			onClick={buttonAction}
			disabled={disabled}
			title={tooltip}
		>
			{children}
		</Button>
	);
}
