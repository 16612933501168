import React from "react";
import RecurringCallbackManager from "../common/RecurringCallbackManager";
import { getMoreListHourlyLimit, getMoreList } from "../redux/recipients/thunks";
import { reduxConnectedComponent } from "../util/reduxConnectedHOC";

const GetMoreListInterval = ({ getMoreListHourlyLimit, getMoreList }) => {
	return (
		<RecurringCallbackManager
			intervalTime={1}
			intervalName="getMoreList"
			task={() => getMoreListHourlyLimit()}
			// task={() => getMoreList()}
			// executeTaskAtStart={true}
		/>
	);
};

const GetMoreListIntervalComponent = reduxConnectedComponent(GetMoreListInterval, undefined, {
	getMoreListHourlyLimit,
	getMoreList,
});
export default GetMoreListIntervalComponent;
