import { loadStateReducer } from "../../util/reusableReducers";
import { RELEASE_INACTIVE_PREFIX } from "./types";

// This is a reducer that handles the state of the inactive recipients
const releaseInactiveReducer = loadStateReducer({
	[RELEASE_INACTIVE_PREFIX + "_IS_LOADING"]: "loading",
	[RELEASE_INACTIVE_PREFIX + "_HAS_ERROR"]: "error",
	[RELEASE_INACTIVE_PREFIX + "_POST_SUCCESS"]: "success",
});

export default releaseInactiveReducer;
