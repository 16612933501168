// Selectors are convenience functions for retrieving parts of the state
import { PERMANENT_OPT_OUT_TERMS } from "../../util/constants";
import { evaluateConditionals } from "../../util/scriptLogic";

/**
 * Check if a given conversation is actionable
 * @param {Object} recipientsState The current state OF THE RECIPIENT REDUCER!!!
 * @param {string} phone Phone number to check
 * @returns {Object}
 */
export function isConversationActionable(recipientsState, phone) {
	const recipient = recipientsState.numbers[phone];
	return (
		recipient &&
		!recipient.terminating &&
		(recipient.conversation.length === 0 ||
			recipient.conversation[recipient.conversation.length - 1].who !== "sender")
	);
}

/**
 * Get the a recipient's variable values by phone number
 * @param {*} state The full redux state
 * @param {*} phone The phone number of the recipient
 * @returns {object} The recipient's variables
 */
export const getRecipientMisc = (state, phone) => state.recipients.numbers[phone].misc;

/**
 * Get the campaign script
 * @param {*} state The full redux state
 * @returns {array} script
 */
export const getScript = (state) => state.campaign.script;

/**
 * Get the numbers script
 * @param {*} state The full redux state
 * @returns {object} numbers
 */
export const getNumberList = (state) => state.recipients.numbers;

export const getRecipient = (state, phone) => getNumberList(state)[phone];

export const getRecipientTranscript = (state, phone) => getRecipient(state, phone).conversation;

/**
 * Gets the stored incoming msg if there is one
 * @param {object} state The global redux state
 * @param {string} phone The recipient 10dlc phone
 * @returns {object | undefined}
 */
export const getDelayedIncoming = (state, phone) => state.recipients.delayed[phone];

/**
 * Takes the partial state of the script and returns the question
 * the recipient is on
 * @param {*} scriptState the
 * @returns {object} the current question
 */
export const getCurrentQuestion = (scriptState, currentQuestionId) => {
	let q = scriptState.find((item) => item.id === currentQuestionId);
	if (!q) {
		q = scriptState[0];
	}
	return q;
};

/**
 * TODO: Putting this here for now but I'm unclear as to where it should live
 * @param {*} state
 * @param {number} phone
 * @param {Array<Object>} updatedTranscript
 * @param {number} scriptid
 * @returns
 */
export const getConversationNextScript = (state, phone, updatedTranscript, scriptid) => {
	const recipients = getNumberList(state);
	const script = getScript(state);

	// Use scriptid if passed, or the state value, or finally the first id in the script
	let curQId = script[0].id;
	if (scriptid) {
		curQId = scriptid;
	} else if (recipients[phone].currentscriptid) {
		curQId = recipients[phone].currentscriptid;
	}

	const curQ = getCurrentQuestion(script, curQId);
	const variables = recipients[phone].misc;

	// If a blob has been added to the transcript, the updated one is passed in
	let transcript = updatedTranscript ? updatedTranscript : recipients[phone].conversation;

	// Every question has a default next, so this should always be skipped
	if (!curQ.defaultNext) {
		curQ.defaultNext = script[script.findIndex((q) => q.id === curQId) + 1].id;
	}

	// If it's a closing question we don't add it to the transcript because we're just closing
	// (we might want to change that just make this more straightforward)
	//
	// Also, if the currentscriptid doesn't exist in the script at all then we shouldn't proceed.

	// NOTE: if the last question isn't an accepted value then this isn't getting applied. ( which begs the question of why the hell it's running at all?)
	if (
		!["closing", "terminating"].includes(curQ.type) &&
		transcript.find((item) => item.currentscriptid === curQId) === undefined
	) {
		// Only log accepted values since that's the only time we skip to a new question
		return curQId;
	}

	const result = evaluateConditionals(
		curQ.conditionalNexts, // array of conditionals (conditional & goto)
		variables, // {variables} available within conditionals
		transcript, // [answers] available within conditionals
		curQ.defaultNext, // default if all conditionals fail
	);

	// TODO: could we just check here if the curQId is in the conversation?
	// The curQID is currentscriptid. But is this ever called before the question is asked?
	// if so we can't just check for missing convo in the transcript
	// NOTE: since closing and terminating questions don't get added to the transcript
	//        we should disregard them in our check for the id in the transcript

	return result;
};
