import React, { Component } from "react";
import { reduxConnectedComponent } from "../util/reduxConnectedHOC";
import RecurringCallbackManager from "../common/RecurringCallbackManager";
import { millisecondToSeconds } from "../util/helper";

const styles = {
	leftColumn: {
		textAlign: "left",
		fontweight: "bold",
	},
	rightColumn: {
		textAlign: "left",
		// "font-weight": "bold",
	},
	container: {
		marginRight: "20px",
	},
};

const AgentStatisticsView = ({ outboundMessageCount, minuteCount, timeSinceUpdate }) => {
	const lastUpdateText =
		timeSinceUpdate / 60 > 1 ? `${Math.round(timeSinceUpdate / 60)} m` : `${timeSinceUpdate} sec`;

	let hours = 0;
	let minutes = 0;

	if (minuteCount !== undefined) {
		hours = Math.floor(minuteCount / 60);
		minutes = minuteCount % 60;
		hours = isNaN(hours) ? 0 : hours;
		minutes = isNaN(minutes) ? 0 : minutes;
	}

	return (
		<div style={styles.container}>
			<table>
				<tbody>
					<tr>
						<td style={styles.leftColumn}>
							<b>Sent SMS / 10 m</b>
						</td>
						<td style={styles.rightColumn}>: {outboundMessageCount}</td>
					</tr>
					<tr>
						<td style={styles.leftColumn}>
							<b>Time logged</b>
						</td>
						<td style={styles.rightColumn}>
							: {hours} h, {minutes} m
						</td>
					</tr>
					<tr>
						<td style={styles.leftColumn}>
							<b>Last Updated</b>
						</td>
						<td style={styles.rightColumn}>: {lastUpdateText} ago</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

class LastUpdatedDate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeSinceUpdate: 0,
		};
	}

	update = () =>
		this.setState({
			timeSinceUpdate: Math.round(millisecondToSeconds(new Date() - this.props.lastUpdated)),
		});

	render = () => (
		<React.Fragment>
			<AgentStatisticsView timeSinceUpdate={this.state.timeSinceUpdate} {...this.props} />
			<RecurringCallbackManager task={this.update} intervalTime={0.5} intervalName="dateInterval" />
		</React.Fragment>
	);
}

const AgentStatisticsViewContainer = reduxConnectedComponent(LastUpdatedDate, (state) => ({
	...state.agent.processed,
}));

export default AgentStatisticsViewContainer;
