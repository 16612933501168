import React from "react";
import ActionWithConfirmationNav from "./ActionWithConfirmationWrapper";

export default function TerminateNav({ currentPhone, endAndRemoveConversation, closeMenu, className = "" }) {
	return (
		<ActionWithConfirmationNav
			className={className}
			currentPhone={currentPhone}
			// We must use `asyc` here to keep the contract with the `ActionWithConfirmationNav`, as it relies on the `finally` property of the promise interface
			actionToConfirm={async (phone) => await endAndRemoveConversation(phone, "terminated", "agent terminate")}
			actionText="Terminate"
			confirmationModal={{
				headerText: "Terminate Conversation",
				bodyText: "Are you sure you want to terminate this conversation?",
				confirmText: "Terminate",
			}}
		/>
	);
}
