import React from "react";
import PropTypes from "prop-types";

export function BottomPanel({ leftComponentContent = null, rightComponentContent = null }) {
	return (
		<div className="bottom-panel-wrapper columns m-0 p-0">
			<div className="m-0 p-0 column is-2" /> {/* Empty column to allow the sidebar to overflow vertically into*/}
			<div className="m-0 column is-8 p-0">
				<div className="pl-0">
					<div className="bottom-panel">
						{leftComponentContent}
						{rightComponentContent}
					</div>
				</div>
			</div>
		</div>
	);
}

BottomPanel.propTypes = {
	leftComponentContent: PropTypes.element,
	rightComponentContent: PropTypes.element,
};
