import { FEED_TYPE_MSG_MAP, FeedEventType } from "./constants";
import * as actionTypes from "./types";

/**
 * Structure of the feed events
 * @typedef {object} FeedEvent
 * @property {number} id The UTC creation time / id
 * @property {number} feedEventType The type of event
 * @property {string} message A custom message for the event
 * @property {number} recipientId The unique identifier for the recipient (phonelist_id)
 */

/**
 * Generates a unique feedEvent ID based on the UTC time.
 * > Not the same as the notification because I use it to determine
 * > the age of the notification
 * @returns {number} The generated feedEvent ID.
 */
export function generateFeedEventId() {
	let lastId = Date.now();
	return ++lastId;
}

/**
 * @param {number} eventTypeValue
 * @param {number} recipientId
 * @returns {FeedEvent}
 */
export function getFeedEvent(eventTypeValue, recipientId) {
	return {
		id: generateFeedEventId(),
		feedEventType: eventTypeValue,
		message: FEED_TYPE_MSG_MAP[eventTypeValue],
		recipientId: recipientId,
	};
}

/**
 * Adds a new feedEvent.
 * @param {Object} feedEvent - The feedEvent object to be added.
 * @returns {Function} A Redux thunk function.
 */
export function addFeedEvent(feedEvent) {
	return (dispatch) =>
		dispatch({
			type: actionTypes.ADD_FEED_EVENT,
			feedEvent,
		});
}

/**
 * Updates an existing feedEvent.
 * @param {number} id - The ID of the feedEvent to be updated.
 * @param {Object} eventUpdates - The updated properties of the feedEvent.
 * @param {string} eventUpdates.message - The updated message of the feedEvent.
 * @param {string} eventUpdates.status - The updated status of the feedEvent.
 * @returns {Function} A Redux thunk function.
 */
export function updateFeedEvent(id, eventUpdates) {
	return (dispatch) =>
		dispatch({
			type: actionTypes.UPDATE_FEED_EVENT,
			feedEvent: {
				id,
				...eventUpdates,
			},
		});
}

/**
 * Removes a feedEvent.
 * @param {number} id - The ID of the feedEvent to be removed.
 * @returns {Function} A Redux thunk function.
 */
export function removeFeedEvent(id) {
	return (dispatch) =>
		dispatch({
			type: actionTypes.REMOVE_FEED_EVENT,
			id,
		});
}

export function removeTail() {
	return (dispatch) =>
		dispatch({
			type: actionTypes.REMOVE_TAIL,
		});
}

export const eventSelected = (event) => ({
	type: actionTypes.EVENT_SELECTED,
	event,
});
