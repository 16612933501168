import React from "react";
import RecurringCallbackManager from "../../common/RecurringCallbackManager";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { getAgentStatistics } from "../../redux/agent/thunks";
const COMPLETE_TIMEOUT = process.env.REACT_APP_STATISTICS_INTERVAL || 1;

/**
 * This only gets the data. The displaying of it is done via Redux.
 * @param {*} props
 * @returns
 */
export const AgentStatisticsManager = (props) => (
	<RecurringCallbackManager
		intervalTime={Number(COMPLETE_TIMEOUT)}
		intervalName="agentStats"
		task={props.getAgentStatistics}
		executeTaskAtStart={true}
	/>
);

const AgentStatisticsManagerContainer = reduxConnectedComponent(AgentStatisticsManager, undefined, {
	getAgentStatistics,
});

export default AgentStatisticsManagerContainer;
